import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from './DynamicForm';
import { colors } from '../theme';

function formatDateShort(isoDateString) {
    var date = new Date(isoDateString);
    var day = date.getDate().toString().padStart(2, '0');
    var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
    var year = date.getFullYear();
    var hours = date.getHours().toString().padStart(2, '0');
    var minutes = date.getMinutes().toString().padStart(2, '0');
    return day + "." + month + " " + hours + ":" + minutes;
  }

function OrderGoogleClient({ orderInfo, onCompleteFunction, initialData}) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();
    const [objects, setObjects] = useState([]);

    const loadData = async (value, key) => {
        let data = await api.jsonRpcRequest('orders.google.clients', { hash: orderInfo.hash });
        data = data.objects.map(item => {
            return { value: item.id, label: `${formatDateShort(item.created_datetime)} | Campaing: ${item.campaign_name} | Client: ${item.ga_client} | Event: ${item.crm_event_type}` };
        });
        setObjects(data);
    };

    useEffect(() => {
        loadData();
    }, []);

    const saveCustomHandler = async (data) => {
        console.log(data)
        await api.jsonRpcRequest('orders.update', { hash: orderInfo.hash, google_client_id: data.google_client_id });
        if(onCompleteFunction) onCompleteFunction()
    };

    const formConfig = {
        'fields': [
            {
                'title': translate('OrdersPage.table.fields.promo_code'),
                'key': 'google_client_id',
                'type': 'select',
                'options': objects,
                'required': true,
            },
        ]
    };

    return (
        <div>
            <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} initialData={initialData} />
        </div>
    );
}

export default OrderGoogleClient;
