export const uk = {
    'errors': {
        'promoCodeNotFound': 'Промокод не знайдено!',
        'promoCodeTotalActivationsExpired': 'Промокод активовано максимальну кількість разів!',
        'promoCodeClientActivationsExpired': 'Промокод активовано клієнтом максимальну кількість разів!'
    },
    'LoginPage': {
        'emailLabel': 'Ваша пошта',
        'passwordLabel': 'Пароль',
        'submitBtnLabel': 'Увійти'
    },
    'DocumentPage': {
        'table': {
            'fields': {
                'document_date': 'Дата документу',
                'document_type': 'Тип документу',
                'in': 'Додати на склад',
                'out': 'Списати зі складу',
                'article_name': 'Назва',
                'rest': 'Залишок',
                'quantity': 'Кіл-ть',
                'price': 'Ціна',
                'id': 'ID',
                'sum': 'Сума',
                'is_approved': 'Перевірено',
                'order': 'Відкрити замовлення',
                'prime_cost': 'Собівартість',
                'move_to_warehouse': 'Виконати переміщення на склад',
                'warehouse': 'Склад',
                'usd_price': 'Ціна (USD)',
                'usd_sum':  'Сума (USD)',
            }
        }
    },
    'OrderDocumentOut': {
        'warehouse': 'Склад',
        'article': 'Назва',
        'quantity': 'Кіл-ть'
    },
    'Menu': {
        'employees': 'Співробітники',
        'equipment': 'Обладнання',
        'orders': 'Замовлення',
        'accounting': 'Бухгалтерія',
        'reports': 'Звіти',
        'client_debts': 'Клієнти (борги)',
        'employee_debts': 'Співробітники (борги)',

        'home': 'Dashboard',
        'articles': 'Товари',
        'categories': 'Категорії',
        'products_extra_groups': 'Опції',
        'analytics': 'Аналітика',
        'fb': 'Facebook',
        'google': 'Google Ads',
        'journal': 'Щоденник',
        'calendar': 'Календар',
        'analytics_common': 'Загальна',
        'tasks': 'Задачі',
        'analytics_documents': 'Документи',
        'analytics_documents_stock': 'Залишки на складі',
        'reports_orders': 'Замовлення',
    },
    'JournalPage': {
        'form': {
            'session_start': 'Початок',
            'session_end': 'Завершення',
        },
        'table': {
            'actionButtons': {
                'add_event': 'Додати подію'
            },
            'fields': {
                'user_email': 'Співробітник',
                'create_time': 'Дата, час',
                'content': 'Подія',
            },
        }
    },
    'TasksPage': {
        'form': {
            'session_start': 'Початок',
            'session_end': 'Завершення',
        },
        'table': {
            'actionButtons': {
                'add_event': 'Додати подію'
            },
            'fields': {
                'order_address': 'Адреса',
                'text': 'Задача',
                'responsible_user': 'Відповідальний',
                'created_datetime': 'Дата створення',
                'deadline_datetime': 'Виконати до',
                'completed_datetime': 'Завершено',
                'additional_filter_all': 'Всі',
                'additional_filter_active': 'Потрібно виконати',
                'additional_filter_inactive': 'Виконані',
            },
        }
    },
    'EquipmentsPage': {
        'table': {
            'fields': {
                'equipment_type': 'Тип',
                'warehouse': 'Склад',
                'hash': 'Код',
                'serial_number': 'Серійний номер',
                'manufacturer_name': 'Бренд',
                'manufacturer_model': 'Модель',
                'description': 'Опис',
                'file': 'Файл',
            }
        }
    },
    'AnalyticsCommonPage': {
        'table': {
            'fields': {
                'campaign_name': 'Name',
                'campaign_cost_total': 'Total',
                'orders_count': 'Orders',
                'orders_is_completed': 'Completed',
                'orders_is_completed_sum': 'Completed (SUM)',
                'orders_is_completed_percent': 'Completed (%)',
                'orders_is_completed_cost_lead': 'Completed lead cost',
                'orders_is_completed_roas': 'Completed ROAS',
                'orders_is_completed_roas_clean': 'Completed ROAS (with staff)',
                'orders_is_confirmed': 'Confirmed',
                'orders_is_confirmed_sum': 'Confirmed (SUM)',
                'orders_is_confirmed_percent': 'Confirmed (%)',
                'orders_is_confirmed_cost_lead': 'Confirmed lead cost',
                'orders_is_confirmed_roas': 'Confirmed ROAS',
                'orders_is_confirmed_roas_clean': 'Confirmed ROAS (with staff)',
                'orders_is_failed': 'Failed',
                'orders_is_failed_sum': 'Failed (SUM)',
                'orders_is_failed_percent': 'Failed (%)',
                'orders_is_failed_cost_lead': 'Failed lead cost',
                'orders_is_failed_roas': 'Failed ROAS',
                'orders_is_failed_roas_clean': 'Failed ROAS (with staff)',
                'orders_is_canceled': 'Canceled',
                'orders_is_canceled_percent': 'Canceled (%)',
                'orders_others': 'Others',
                'orders_others_percent': 'Others (%)',
            }
        },
        'form': {
            'month': 'Місяць',
            'year': 'Рік',
            'step': 'Крок',
            'day': 'День',
            'week': 'Тиждень',
            'from': 'Від',
            'to': 'До',
        }
    },
    'OrderEmployeeAddPayment': {
        'form': {
            'amount': 'Сума',
            'employee': 'Клінер',
        }
    },
    'AccountingOperationsPage': {
        'table': {
            'fields': {
                'date': 'Дата',
                'user_email': 'Користувач',
                'amount': 'Сума',
                'currency': 'Валюта',
                'usd_amount': 'USD',
                'expense_item_name': 'Тип',
                'notes': 'Примітка',
                'debt_from': 'Хто винен',
                'debt_to': 'Кому винен',
                'in': 'Прихід',
                'out': 'Витрата',
                'balance': 'Баланс',
                'wallet': 'Гаманець',
            },
            'actionButtons': {
                'adjuction': 'Прихід',
                'substraction': 'Витрата',
                'transfer': 'Трансфер',
                'exchange': 'Обмін',
                'debts': 'Борги',
                'total': 'Підсумок',
            }
        },
        'form': {
            'amount': 'Сума',
            'notes': 'Примітка',
            'currency': 'Валюта',
            'to_user_id': 'Користувач, якому зробили переказ',
            'expense_item': 'Тип платежу',
            'wallet': 'Гаманець',
        }
    },
    'EmployeesPage': {
        'table': {
            'fields': {
                'date_hire': 'Дата прийняття',
                'date_dismissal': 'Дата звільнення',
                'full_name': 'Повне ім\'я',
                'short_name': 'Ім\'я',
                'phone': 'Телефон',
                'home_address': 'Домашня адреса',
                'gov_id_number': 'Номер паспорта',
                'gov_id_date': 'Дата видачі паспорта',
                'gov_id_office': 'Орган видачі паспорта',
                'photo': 'Фото',
                'verified': 'Перевірено',
                'not_verified': 'Не перевірено',
                'position': 'Тип',
                'cleaner': 'Клінер',
                'cleaner_manager': 'Бригадир',
                'sales_manager': 'Відділ продажу',
                'office_manager': 'Офіс-менеджер',
                'quality_assurance': 'QA',
                'only_active': 'Тільки працюючі',
                'telegram_id': 'Telegram ID',
            },
            'links': {
                'sessions': 'Зміни'
            }
        }
    },
    'OrderAddress': {
        'form': {
            'address_locality': 'Місто',
            'address_name': 'Вулиця, будинок',
            'address_apartment': 'Квартира',
            'address_comment': 'Примітка',
        }
    },
    'OrderEmployeeSearch': {
        'form': {
            'assign': 'Призначити'
        }
    },
    'OrderItem': {
        'form': {
            'service': 'Послуга',
            'price': 'Вартість',
        }
    },
    'OrderEmployee': {
        'form': {
            'employee': 'Відповідальний',
        }
    },
    'OrderEmployeeCancel': {
        'form': {
            'reason': 'Причина',
        }
    },
    'OrderTask': {
        'form': {
            'text': 'Завдання',
            'responsible_user': 'Відповідальний співробітник',
            'deadline_datetime': 'Виконати до',
            'complete_task': 'Завершити'
        }
    },
    'OrderEquipment': {
        'form': {
            'equipment': 'Обладнання',
        }
    },
    'OrdersPage': {
        'table': {
            'fields': {
                'group_state': 'Воронка',
                'tasks': 'Задачі',
                'skip_if_exist': 'Створити нову угоду якщо існує',
                'address_name': 'Адреса',
                'promo_code': 'Промокод',
                'client_full_name': 'Клієнт',
                'bonus_balance': 'Бонусний баланс',
                'balance': 'Баланс',
                'client_phone': 'Контактний телефон',
                'scheduled_datetime': 'Час початку',
                'created_datetime': 'Час створення',
                'price': 'Сума замовлення',
                'user_fin_operations_sum': 'Сума оплат',
                'state': 'Статус',
                'additional_filter': 'Додаткові фільтри',
                'additional_filter_all': 'Всі угоди',
                'additional_filter_active': 'Активні угоди',
                'additional_filter_inactive': 'Не активні угоди',
                'additional_filter_success': 'Успішні угоди',
                'additional_filter_failed': 'Втрачені угоди',
                'google_campaing': 'Рекламна кампанія',
                'google_client': 'Analytics client ID',
                'order_employee_payments_sum': 'Сума ЗП',
                'user_fin_operations_sum': 'Сума оплат',
            }
        },
        'form': {
            'pay': 'Оплати (готівка/безготівка)',
            'employee_payments': 'Платежі клінерів',
            'out_document': 'Витрати хімії',
            'sale_scripts': 'Скрипт продажу'
        }
    },
    'AccountingClientDebtsPage': {
        'table': {
            'fields': {
                'client_full_name': 'Ім\'я клієнта',
                'client_phones': 'Контактний телефон',
                'client_orders_sum': 'Сума замовлень',
                'client_user_fin_operations_sum': 'Сума оплат (готівка/безготівка)',
                'client_bonus_history_order_subs_sum': 'Сума оплат (бонуси)',
                'client_balance': 'Баланс',
                'client_bonus_history_sum': 'Бонусний баланс',
                'client_employee_position': 'Тип',
            },
            'help_text': {
                'client_balance': 'Якщо менше 0, значить повинні нам. Якщо більше 0, значить повинні ми.'
            }
        }
    },
    'EmployeeSessionsPage': {
        'form': {
            'session_start': 'Початок зміни',
            'session_end': 'Завершення зміни',
        }
    },
    'AccountingCalendarPage': {
        'form': {
            'start': 'Початок',
            'end': 'Завершення',
        }
    },
    'common': {
        'table': {
            'actions': 'Дії',
            'add': 'Додати',
            'add_service': 'Додати послугу',
            'add_staff': 'Додати клінера',
            'add_equipment': 'Додати обладнання',
            'add_comment': 'Додати коментар',
            'add_task': 'Додати задачу',
            'search_staff': 'Підібрати клінерів'
        },
        'form': {
            'save': 'Зберегти',
            'remove': 'Видалити',
            'required': 'Обов\'язкове поле',
            'confirm_delete': 'Ви впевнені, що хочете видалити?',
            'filter': 'Фільтр',
            'empty': 'не вказано',
            'view': 'Переглянути',
            'close': 'Закрити',
        },
        'toast': {
            'saved': 'Збережено',
            'removed': 'Видалено',
            'no_employees_fot_order': 'Немає виконавців на замовлення',
        },
        'order': {
            'states': {
                'new': 'Новий',
                'wait_for_confirmation': 'Очікує підтвердження',
                'confirmed': 'Підтверджено',
                'in_process': 'В роботі',
                'finished': 'Роботу завершено',
                'completed': 'Виконано',
                'canceled': 'Скасовано'
            }
        }
    },
    'TOTPPage': {
        'form': {
            'totp_secret': 'Скануй qr код і натискай кнопку зберегти'
        }
    },
    'OrderComment': {
        'form': {
            'comment': 'Коментар'
        }
    },
}
