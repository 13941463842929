import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { translate } from "../utils/localization-helper";
import DynamicForm from "../components/DynamicForm";
import { colors } from "../theme";

function OrderLogs({ orderInfo }) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    const eventIcons = {
        'e3': "📊",
        'e2': "🛒",
        'e1': "🔄",
    };

    return (
        <div style={styles.container}>
            {orderInfo?.logs?.map((log, index) => (
                <div key={index} style={styles.logItem}>
                    <div style={styles.iconContainer}>
                        <span>{eventIcons[`e${log.event_type}`] || "❓"}</span>
                    </div>
                    <div style={styles.content}>
                        <p style={styles.eventName}>{log.state_name || translate("No Name")}</p>
                        <span style={styles.eventDate}>
                            {new Date(log.created_datetime).toLocaleString()}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
}

const styles = {
    container: {
    },
    logItem: {
        display: "flex",
        alignItems: "center",
        marginTop: "5px",
        marginBottom: "5px",
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        backgroundColor: "#f0f0f0",
    },
    content: {
        flex: 1,
    },
    eventType: {
        margin: 0,
        fontSize: "16px",
        fontWeight: "bold",
    },
    eventName: {
        margin: 0,
        fontSize: "14px",
        color: "#555",
    },
    eventDate: {
        marginTop: "4px",
        fontSize: "12px",
        color: "#999",
    },
};

export default OrderLogs;
